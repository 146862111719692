import * as React from 'react'

export const Footer = () => {

  return (
    <>
    <footer className="mt-auto">
      <div className="footer">
        <div className="container">
          <div className="row gy-3 pb-4">
            <div className={"col-12 col-lg-6 col-xl-4"}>
              <a href="/"><img src="/images/logo.png" alt="" width="162" height="40" className="footer__logo"/></a>
              <div className="footer__text">Das Leben ist viel zu kurz, um schlechten Wein zu trinken – Johann Wolfgang von Goethe</div>
            </div>
            <div className={"col-12 col-lg-6 col-xl-4"}>
              <div className="footer__menu-title">MENÜ</div>
              <div className="footer__menu">
                <div className="footer__menu-row">
                  <div className="footer__menu-col">
                    <ul className="footer__menu-list">
                      <li><a href="/">HOME</a></li>
                      <li><a href="/weinangebote">WEINANGEBOTE</a></li>
                      <li><a href="/wein-gutscheine">WEIN-GUTSCHEINE</a></li>
                      <li><a href="/blog">BLOG</a></li>
                    </ul>
                  </div>
                  <div className="footer__menu-col">
                    <ul className="footer__menu-list">
                      <li><a href="/imprint">IMPRINT</a></li>
                      <li><a href="/datenschutzerklarung">DATENSCHUTZERKLÄRUNG</a></li>
                      <li><a href="/cookie-erklarung">COOKIE-ERKLÄRUNG</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__copyright">© Alle Rechte vorbehalten.</div>
        </div>
      </div>
    </footer>
    </>
  )
}
