import * as React from 'react'
import { graphql } from 'gatsby'

export const Header = ({ slice }) => (
  <>
    <header>
      <div className="header">
        <div className="container">
          <div className="header__bow">
            <div className="header__row">
              <div className="header__col">
                <h1 className="header__title text-white">{slice.primary.title}<span className="ico-glasses"></span></h1>
              </div>
              <div className="header__col hidden-lg hidden-md">
                <img src="images/header/main.png" alt="" className="header__image"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </>
)

export const query = graphql`
  fragment HomepageDataBodyHeader on PrismicHomepageDataBodyHeader {
    primary {
      title
    }
  }
`
