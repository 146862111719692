import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const EvaluationTable = ({ slice }) => {

  const {title, title_m, subtitle} = slice.primary

  const table = slice.items.map((item, index) => {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>
          <a target="_blank" href={item.link.url}><img src={item.logo.url} alt="" className="b-rating__table-logo" height="60" width="60"/></a>
          <div className="b-rating__table-name">{item.name}</div>
          <a target="_blank" href={item.link.url} className="b-rating__table-shop-link">Shop besuchen</a>
        </td>
        <td>
          <div className="b-rating__table-evaluation"><span className="bold">{item.evaluation}</span><span>100</span></div>
        </td>
        <td>
          <div className="b-rating__table-availability">{item.wines_in_stock}</div>
        </td>
        <td><img src={item.price_mark.url} alt="" height="54" width="54"/></td>
        <td><img src={item.offers_mark.url} alt="" height="54" width="54"/></td>
        <td><img src={item.reviews_mark.url} alt="" height="54" width="54"/></td>
        <td className="b-rating__table-delivery">
          <PrismicRichText field={item.delivery_cost.richText} />
        </td>
        <td className="b-rating__table-advantages">
          <PrismicRichText field={item.features.richText} />
        </td>
        <td>
          <img style={{marginBottom:"20px"}} src={item.team_experience_mark.url} alt="" height="54" width="54"/><br/>
          <a target="_blank" href="" className="b-rating__table-reviews-link">See Review</a>
        </td>
      </tr>
    )
  })

  const catalogue = slice.items.map((item, index) => {
    return (
      <div key={index} className="b-catalog__col">
        <div className="b-catalog__item">
          <div className="b-catalog__item-content">
            {item.label && <a target="_blank" rel="nofollow" href={item.link.url} className="b-catalog__item-tape">{item.label}</a>}
            <div className="b-catalog__item-header">
              <div className="b-catalog__item-logo-wrap">
                <img src={item.logo.url} alt="" className="b-catalog__item-logo"/>
              </div>
              <div className="b-catalog__item-rating">
                <div className="b-catalog__item-rating-number">{item.rating + " "}<span className="count">({item.votes})</span></div>
                <div className="b-catalog__item-rating-stars">
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 1) ? ((item.rating - 1)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 2) ? ((item.rating - 2)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 3) ? ((item.rating - 3)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 4) ? ((item.rating - 4)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 5) ? ((item.rating - 4)*100 + "%") : "100%"}}></span></div>
                </div>
              </div>
            </div>
            <h3 className="b-catalog__item-title">{item.name}</h3>
            <div className="b-catalog__item-progress progress progress-bar-striped">
              <div className="progress-bar" role="progressbar" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100" style={{width: "82%"}}></div>
            </div>
            <div className="b-catalog__item-progress-text">{item.evaluation}/100</div>
            <ul className="b-catalog__item-list">
              <li>Vorrätige Weine: {item.wines_in_stock}</li>
              <li style={{display:"flex",alignItems:"center"}}>Preise: <img style={{marginLeft:"8px"}} src={item.price_mark.url} alt="" height="30" width="30"/></li>
              <li style={{display:"flex",alignItems:"center"}}>Angebote: <img style={{marginLeft:"8px"}} src={item.offers_mark.url} alt="" height="30" width="30"/></li>
            </ul>
          </div>
            <a target="_blank" rel="nofollow" href={item.link.url} className="b-catalog__item-btn"><span>Shop besuchen</span></a>
        </div>
      </div>
    )
  })

  return (
    <>
      {/*desktop*/}
      <div className="b-rating hidden-sm hidden-xs" id="rating">
        <div className="container">
          <h2 className="b-rating__title">{title}</h2>
          <div className="b-rating__subtitle">{subtitle}</div>
          <div className="table-responsive">
            <table className="table table-bordered b-rating__table" id="rating-table">
              <thead>
                <tr>
                  <th>PLATZ</th>
                  <th>SHOPNAME</th>
                  <th>BEWERTUNG</th>
                  <th>VORRÄTIGE WEINE</th>
                  <th><div style={{minWidth:"54px"}}>PREISE</div></th>
                  <th>SPEZIALAN GEBOTE</th>
                  <th>KUNDENBE WERTUNGEN</th>
                  <th>LIEFERKOSTEN</th>
                  <th>COOLE BESONDERHEITEN</th>
                  <th>ERFAHRUNG DES TEAMS</th>
                </tr>
              </thead>
              <tbody>
                {table}
              </tbody>
            </table>
          </div>
          <div className="b-rating__link-more-wrap">
            <a className="b-rating__link-more" role="button" aria-expanded="false" data-toggle="#rating-table">
              <span className="btn-more">Alle ansehen</span>
              <span className="btn-less">Top 3 anzeigen</span>
            </a>
          </div>
        </div>
      </div>
      {/*mobile*/}
      <div className="b-catalog hidden-lg hidden-md" id="catalog">
        <div className="container">
          <h2 className="b-catalog__title">{title_m}</h2>
          <div className="b-catalog__subtitle">{subtitle}</div>
          <div className="b-catalog__row" id="catalog-row">
            {catalogue}
          </div>
          <div className="b-catalog__link-more-wrap">
            <a className="b-catalog__link-more" role="button" aria-expanded="false" data-toggle="#catalog-row">
              <span className="btn-more">Alle ansehen</span>
              <span className="btn-less">Top 5 anzeigen</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  fragment HomepageDataBodyEvaluationTable on PrismicHomepageDataBodyEvaluationTable {
    primary {
      title
      title_m
      subtitle
    }
    items {
      logo {
        url
      }
      price_mark {
        url
      }
      offers_mark {
        url
      }
      reviews_mark {
        url
      }
      team_experience_mark {
        url
      }
      name
      label
      rating
      votes
      evaluation
      wines_in_stock
      delivery_cost {
        richText
        text
      }
      features {
        richText
      }
      link {
        url
      }
    }
  }
`
