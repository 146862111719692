import { Header } from './Header'
import { TopShops } from './TopShops'
import { EvaluationTable } from './EvaluationTable'
import { AboutRatingSystem } from './AboutRatingSystem'
import { AboutUs } from './AboutUs'
import { ContactForm } from './ContactForm'
import { EventInfo } from './EventInfo'
import { Coupons } from './Coupons'
import { PostInfo } from './PostInfo'
import { TitleAndText } from './TitleAndText'
import { EmbeddedHtml } from './EmbeddedHtml'

export const components = {
	header: Header,
	top_shops: TopShops,
	evaluation_table: EvaluationTable,
	about_rating_system: AboutRatingSystem,
	about_us: AboutUs,
	contact_form: ContactForm,
	event_info: EventInfo,
	coupons: Coupons,
	post_info: PostInfo,
	title_and_text: TitleAndText,
	embedded_html: EmbeddedHtml,
}
