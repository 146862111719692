import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment-timezone'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Coupon = ({item, index, urlCode, urlShop}) => {

  let fl = false
  if (urlShop==item.shop_name && urlCode == item.code) {
    fl = true
  }
  let [show, setShow] = useState(fl);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function couponCLick(link) {
    const currentUrl = location.protocol + '//' + location.host + location.pathname
    if (typeof window !== `undefined`) {
      window.open(currentUrl+"?shop="+item.shop_name+"&code="+item.code, '_blank').focus()
      window.open(link, "_self")
    }
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code);
  }

  const expire = moment(item.expire).format("DD MMMM YYYY")
  if (moment(item.expire) > moment()) {
    return (
      <div key={index} className="col-lg-4">
        <div className={"overflow-hidden bg-white border h-100 d-flex flex-column"}>   
          <div className="bg-light position-relative overflow-hidden" style={{height:"240px"}}>
            {item.discount && <a className="no-decor b-best__item-tape" style={{zIndex:"100"}}>{item.discount}</a>}
            {item.img && <img className="image-cover" src={item.img.url} />}
          </div>
          <div className="d-flex flex-fill flex-column justify-content-between text-center p-4 px-3">
            <div>
              <div className="fs-1125 text-decoration-none text-body mb-2">{item.shop_name}</div>
              <PrismicRichText field={item.desc.richText} />
            </div>
            <div className="">
              <a target="_blank" onClick={() => couponCLick(item.shop_link.url)} className="btn btn-primary">Gutschein anzeigen</a>
              <div className="fs-0875 mt-3">Gültig bis: {expire}</div>
            </div>
          </div>
        </div>
        <Modal id={"modal"+index} show={show} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <div className="fs-1125 text-decoration-none text-body ">{item.shop_name}</div>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
            <PrismicRichText field={item.desc.richText} />
            <div className="input-group mb-3">
              <input type="text" className="form-control text-center fw-bold" style={{height:"auto"}} value={item.code} readOnly/>
              <a className="btn btn-success" onClick={() => copyCode(item.code)}>Code kopieren</a>
            </div>
            <a target="_blank" rel="nofollow" href={item.shop_link.url} className="btn btn-primary">Zum Shop</a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}

export const Coupons = ({ slice, location }) => {
  let searchParams = {}
  let urlCode = {}
  let urlShop = {}
  if (typeof window !== `undefined`) {
    searchParams = new URLSearchParams(window.location.search.substring(1))
    urlCode = searchParams.get("code")
    urlShop = searchParams.get("shop")
  }

  const coupons = slice.items.map((item, index) => {
    return <Coupon key={index} item={item} index={index} urlCode={urlCode} urlShop={urlShop}/>
  })

  

  return (
    <div className="container">
      <div className="row gy-4">
        {coupons}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment CouponsDataBodyCoupons on PrismicCouponsDataBodyCoupons {

    items {
      img {
        url
      }
      desc {
        richText
      }
      shop_name
      shop_logo {
        url
      }
      shop_link {
        url
      }
      code
      discount
      expire
    }
  }
`
