import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { components } from '../slices'
import { Layout } from '../components/Layout'

export const query = graphql`
  query homepageQuery($id: String) {
    prismicHomepage(id: { eq: $id }) {
      _previewable
      id
      lang
      type
      url
      data {
        seo_title
        seo_description
        seo_keywords

        page_title
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
					...HomepageDataBodyHeader
          ...HomepageDataBodyTopShops
          ...HomepageDataBodyTopShops
          ...HomepageDataBodyEvaluationTable
          ...HomepageDataBodyAboutRatingSystem
          ...HomepageDataBodyAboutUs
          ...HomepageDataBodyContactForm
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  return (
    <Layout seo={seo} >
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  )
}
export default withPrismicPreview(Homepage)
