import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TitleAndText = ({ slice }) => {
  const {title, text} = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <h2 className="text-center">{title}</h2>
        <div className="mt-4">
          <PrismicRichText field={text.richText} />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTitleAndText on PrismicPageDataBodyTitleAndText {
    primary {
      title
      text {
        richText
      }
    }
  }
`
