import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const EmbeddedHtml = ({ slice }) => {
  const {html} = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyEmbeddedHtml on PrismicPageDataBodyEmbeddedHtml {
    primary {
      html
    }
  }
`
