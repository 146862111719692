import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment-timezone'
import 'moment/locale/de'  // without this line it didn't work

import Carousel from 'react-bootstrap/Carousel';

export const PostInfo = ({ slice, context }) => {
  const cntx = context
  const {title} = cntx
  const {description, shop_name, shop_logo, shop_link} = slice.primary

  const images = slice.items || []
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  
  const galleryImages = images.map((items, index) => {
    const imgUrl = items.image.url;
    return (
      <Carousel.Item key={index} className="ar16-9">
        <a target="_blank" rel="nofollow" href={shop_link.url} className=""> 
              <img
                src={imgUrl}
                alt="img"
                className="image-cover"
              />
        </a>
      </Carousel.Item>
    );
  });

  const MainInfo = () => (
    <div className="p-4">
        <div className="">
          <div className="d-flex align-items-center">
            {(shop_logo.url && shop_link.url) && <a target="_blank" rel="nofollow" href={shop_link.url}><img className="bg-light position-relative" style={{height:"64px",width:"64px"}} src={shop_logo.url}  alt='' /></a>}
            {(shop_name) && <a target="_blank" rel="nofollow" href={shop_link.url} className="fs-1125 text-decoration-none text-body mb-0 ms-3" style={{color:"#060F19!important"}}>{shop_name}</a>}          
          </div>
          {shop_link.url && <a target="_blank" rel="nofollow" href={shop_link.url} className="mt-3 btn btn-primary">Zum Shop</a>}
        </div>
    </div>
  )

  let links = {}
    if (typeof document !== `undefined`) {
    links = document.links;
    for (var i = 0; i < links.length; i++) {
      links[i].target = "_blank";
      links[i].rel = "nofollow";
    }
  }

  return (
    <div>
      <div className="custom-header" >
        <div style={{height:"76px"}}></div>
          <div className="container py-5">
              <h3 className="text-white mb-0">{title.text}</h3>
          </div>
      </div>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className={"col-lg-8"}>
            <div className="bg-light d-block d-lg-none">
              <MainInfo/>
            </div>
            {isImages && (
              <div className="bg-light ar16-9 mt-4 mt-lg-0" style={{cursor:"pointer"}}>
                  <Carousel>
                    {galleryImages}
                  </Carousel>
              </div>
            )}	
            <div className="mt-4">
              <PrismicRichText field={description.richText} />
            </div>
          </div>
          <div className={(shop_name || shop_logo.url || shop_link.url) ? "col-lg-4" : "d-none"}>
            <div className="sticky-top" style={{top:"24px"}}>
              <div className="bg-light d-none d-lg-block">
                <MainInfo/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PostDataBodyPostInfo on PrismicPostDataBodyPostInfo {
    primary {
      description {
        richText
        text
      }
      shop_name
      shop_logo {
        url
      }
      shop_link {
        url
      }
    }
    items {
      image {
        url
      }
    }
  }
`
