import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const AboutRatingSystem = ({ slice }) => {

  const {title, text, text_end} = slice.primary

  const table = slice.items.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.factor}</td>
        <td>{item.points}</td>
      </tr>
    )
  })

  return (
    <div className="b-system" id="system">
      <div className="container">
        <div className="b-system__box">
          <h2 className="b-system__title">{title}</h2>
          <div className="b-system__text">
            <PrismicRichText field={text.richText} />
          </div>
          <div className="collapse" id="system-collapse">
            <table className="table table-bordered b-system__table">
              <tbody>
                <tr>
                  <th>Faktor</th>
                  <th>Max. Punkte</th>
                </tr>
                {table}
              </tbody>
            </table>
            <div className="b-system__note">
              <PrismicRichText field={text_end.richText} />
            </div>
          </div>
          <div className="b-system__link-wrap">
            <a className="no-decor b-system__link" role="button" data-toggle="collapse" href="#system-collapse" aria-expanded="false" aria-controls="system-collapse">
              <span className="btn-more">Mehr anzeigen</span>
              <span className="btn-less">Weniger anzeigen</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAboutRatingSystem on PrismicHomepageDataBodyAboutRatingSystem {
    primary {
      title
      text {
        richText
      }
      text_end {
        richText
      }
    }
    items {
      factor
      points
    }
  }
`
