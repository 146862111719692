import * as React from 'react'
import { graphql } from 'gatsby'

export const TopShops = ({ slice }) => {

  const {title, subtitle} = slice.primary

  const shops = slice.items.map((item, index) => {
    return (
      <div key={index} className="b-best__col">
        <div className={item.number_1 ? "b-best__item-black" : "b-best__item"}>
            <a target="_blank" rel="nofollow" href={item.link.url} className="no-decor b-best__item-label"><span className="b-best__item-label-text">{item.label}</span></a>
          <div className="b-best__item-content">
            {item.number_1 && <a target="_blank" rel="nofollow" href={item.link.url} className="no-decor b-best__item-tape">#1 Marketplace</a>}
            <div className="b-best__item-header">
              <div className="b-best__item-logo-wrap">
                <img src={item.logo.url} alt="" className="b-best__item-logo"/>
              </div>
              <div className="b-best__item-rating">
                <div className="b-best__item-rating-number">{item.rating + " "}<span className="count">({item.votes})</span></div>
                <div className="b-best__item-rating-stars">
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 1) ? ((item.rating - 1)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 2) ? ((item.rating - 2)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 3) ? ((item.rating - 3)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 4) ? ((item.rating - 4)*100 + "%") : "100%"}}></span></div>
                  <div className="ico-star"><span className="ico-star-inner" style={{width: (item.rating < 5) ? ((item.rating - 4)*100 + "%") : "100%"}}></span></div>
                </div>
              </div>
            </div>
            <h3 className="b-best__item-title">{item.name}</h3>
            <div className="b-best__item-progress progress progress-bar-striped">
              <div className="progress-bar" role="progressbar" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100" style={{width: item.evaluation + "%"}}></div>
            </div>
            <div className="b-best__item-progress-text">{item.evaluation + "/100"}</div>
            <ul className="b-best__item-list">
              <li>{item.feature_1}</li>
              <li>{item.feature_2}</li>
              <li>{item.feature_3}</li>
            </ul>
          </div>
            <a target="_blank" rel="nofollow" href={item.link.url} className="no-decor b-best__item-btn"><span>Shop besuchen</span></a>
        </div>
		  </div>
    )
  })

  return (
    <div className="b-best hidden-sm hidden-xs" id="best">
      <div className="container">
        <h2 className="b-best__title">{title}</h2>
        <div className="b-best__subtitle">{subtitle}</div>
        <div className="b-best__row">
          {shops}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTopShops on PrismicHomepageDataBodyTopShops {
    primary {
      title
      subtitle
    }
    items {
      logo {
        url
      }
      number_1
      name
      label
      rating
      votes
      evaluation
      feature_1
      feature_2
      feature_3
      link {
        url
      }
    }
  }
`
