import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment-timezone'
import 'moment/locale/de'  // without this line it didn't work

import Carousel from 'react-bootstrap/Carousel';

export const EventInfo = ({ slice, context }) => {
  const cntx = context
  const {title, start_time, finish_time} = cntx
  const {description, shop_name, shop_logo, shop_link} = slice.primary

  const images = slice.items || []
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  moment.locale('de')
  const start = moment(start_time).format("DD MMMM YYYY HH:mm")
  const finish = moment(finish_time).format("DD MMMM YYYY HH:mm")

  const now = moment().utc()
  const finishUtc = moment(finish_time).tz("Europe/Berlin")
  const isOver = now.isAfter(finishUtc)

  
  const galleryImages = images.map((items, index) => {
    const imgUrl = items.image.url;
    return (
      <Carousel.Item key={index} className="ar16-9">
        <a target="_blank" rel="nofollow" href={shop_link.url} className=""> 
              <img
                src={imgUrl}
                alt="img"
                className="image-cover"
              />
        </a>
      </Carousel.Item>
    );
  });

  const MainInfo = () => (
    <div className="p-4">
      {isOver &&
      <div className="alert alert-danger" role="alert">{"Aktion liegt in der Vergangenheit"}</div>
      }
      {(shop_name || shop_logo.url || shop_link.url) && 
        <div className=" mb-4">
          <div className="d-flex align-items-center">
            {(shop_logo.url && shop_link.url) && <a target="_blank" rel="nofollow" href={shop_link.url}><img className="bg-light position-relative" style={{height:"64px",width:"64px"}} src={shop_logo.url}  alt='' /></a>}
            {(shop_name) && <a target="_blank" rel="nofollow" href={shop_link.url} className="fs-1125 text-decoration-none text-body mb-0 ms-3" style={{color:"#060F19!important"}}>{shop_name}</a>}          
          </div>
          {shop_link.url && <a target="_blank" rel="nofollow" href={shop_link.url} className="mt-3 btn btn-primary">Zum Shop</a>}
        </div>
      }
      <div className="mt-3 fs-0875">       
        {start_time && 
          <><div style={{color:"#060F19"}}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/event-start.svg`}/>{start}</div><hr/></>
        }
        {finish_time && 
          <><div style={{color:"#060F19"}}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/event-end.svg`}/>{finish}</div></>
        }
      </div>
    </div>
  )

  let links = {}
    if (typeof document !== `undefined`) {
    links = document.links;
    for (var i = 0; i < links.length; i++) {
      links[i].target = "_blank";
      links[i].rel = "nofollow";
    }
  }

  return (
    <div>
      <div className="custom-header" >
        <div style={{height:"76px"}}></div>
          <div className="container py-5">
              <h3 className="text-white mb-0">{(isOver ? "ABGELAUFEN: " : "") + title.text}</h3>
          </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="bg-light d-block d-lg-none">
              <MainInfo/>
            </div>
            {isImages && (
              <div className="bg-light ar16-9 mt-4 mt-lg-0" style={{cursor:"pointer"}}>
                  <Carousel>
                    {galleryImages}
                  </Carousel>
              </div>
            )}	
            <div className="mt-4">
              <PrismicRichText field={description.richText} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sticky-top" style={{top:"24px"}}>
              <div className="bg-light d-none d-lg-block">
                <MainInfo/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment EventDataBodyEventInfo on PrismicEventDataBodyEventInfo {
    primary {
      description {
        richText
        text
      }
      shop_name
      shop_logo {
        url
      }
      shop_link {
        url
      }
    }
    items {
      image {
        url
      }
    }
  }
`
