exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prismic-post-uid-js": () => import("./../../../src/pages/blog/{prismicPost.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-post-uid-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-weinangebote-prismic-event-uid-js": () => import("./../../../src/pages/weinangebote/{prismicEvent.uid}.js" /* webpackChunkName: "component---src-pages-weinangebote-prismic-event-uid-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-coupons-js": () => import("./../../../src/templates/coupons.js" /* webpackChunkName: "component---src-templates-coupons-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

