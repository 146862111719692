import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const AboutUs = ({ slice }) => {

  const {title, text} = slice.primary

  return (
    <div className="b-about" id="about">
      <div className="container">
        <div className="b-about__row">
          <div className="b-about__col-right">
            <div className="b-about__text-wrap">
              <h2 className="b-about__title">{title}</h2>
              <div className="b-about__text">
                <PrismicRichText field={text.richText} />
              </div>
            </div>
          </div>
          <div className="b-about__col-left">
            <img src="/images/about/photo.png" alt="" className="b-about__image" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAboutUs on PrismicHomepageDataBodyAboutUs {
    primary {
      title
      text {
        richText
      }
    }
  }
`
