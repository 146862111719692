import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopNav } from './TopNav'
import { Footer } from './Footer'

export const Layout = ({ children, seo }) => {

  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
        }
      }
    }
  `)

  const lang = 'de'
  const title = seo.title || queryData.site.siteMetadata.title
  const metaDescription = seo.description || queryData.site.siteMetadata.description
  const keywords = seo.keywords || queryData.site.siteMetadata.keywords
  const metaImage = seo.metaImage

  const image =
    metaImage && metaImage.src
      ? `${queryData.site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={[
          {
            href:"https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900",
            rel:"stylesheet",
            type:"text/css",
          },
          {
            href:"https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800",
            rel:"stylesheet",
            type:"text/css",
          },
          {
            href:"https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700",
            rel:"stylesheet",
            type:"text/css",
          },
          {
            href:"https://fonts.googleapis.com/icon?family=Material+Icons",
            rel:"stylesheet",
          },
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: keywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:creator`,
            content: queryData.site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )}
      />
      <section style={{display:"flex",minHeight:"100vh",flexDirection:"column"}}>
        <TopNav />
          <main>{children}</main>
        <Footer />
      </section>
    </>
  )
}
