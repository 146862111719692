import * as React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const TopNav = () => {

  return (
    <>
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark" className="position-absolute">
      <div className="container py-1">
        <Navbar.Brand href="/"><img className="" width="162" height="40" src={"/images/logo.png"}/></Navbar.Brand>
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto mx-auto">
                <Nav.Link href={"/"}>{"Home"}</Nav.Link>
                <Nav.Link href={"/weinangebote"}>{"Weinangebote"}</Nav.Link>
                <Nav.Link href={"/wein-gutscheine"}>{"Wein-Gutscheine"}</Nav.Link>
                <Nav.Link href={"/blog"}>{"Blog"}</Nav.Link>
              </Nav>
              <a className="no-decor scroll-link navbar__btn" href="#contacts"><span className="navbar__btn-text">Kontakt</span></a>
            </Navbar.Collapse>
          </>
      </div>
    </Navbar>
    </>
  )
}
